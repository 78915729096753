import React from "react"
import Container from "../components/Container"
import H1 from "../components/H1"
import H2 from "../components/H2"
import HeroHeader from "../components/HeroHeader"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"

export default ({ data }) => {
  const { html, frontmatter, timeToRead } = data.markdownRemark

  return (
    <Layout>
      <SEO title={`${frontmatter.title} - Steven Musumeche's Blog`} />
      <HeroHeader textAlign="left">
        <Container>
          <H1>{frontmatter.title}</H1>
          <H2>
            {frontmatter.date} ({timeToRead} minute read)
          </H2>
        </Container>
      </HeroHeader>
      <Container dangerouslySetInnerHTML={{ __html: html }} />
      <Container
        css={`
          text-align: center;
        `}
      >
        <Link to="/blog">← Back to Blog List</Link>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      frontmatter {
        date(formatString: "MMMM Do, YYYY")
        title
      }
      html
      excerpt
      timeToRead
    }
  }
`
